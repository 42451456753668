import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import pancakeSwapRouterABI from './pancakeSwapRouterABI.json';
import "./App.css";
import QRCode from 'react-qr-code';
import axios from 'axios';

function App() {

  const [contractAddress, setContractAddress] = useState("null");
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [tokenPriceInBNB, setTokenPriceInBNB] = useState(null);
  const [TwitterText, setTwitterText] = useState(null);
  const [FacebookText, setFacebookText] = useState(null);
  const [TelegramText, setTelegramText] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setContractAddress(response.data[0].value);
      setTitle(response.data[1].value);
      setDescription(response.data[2].value);
      setTokenContract(response.data[3].value);
      setTwitterText(response.data[4].value);
      setFacebookText(response.data[5].value);
      setTelegramText(response.data[6].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
    // Connect to the Binance Smart Chain (BSC)
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');

    // The address of the PancakeSwap Router contract on BSC
    const routerAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E';

    // The address of Binance Coin (BNB) on BSC
    const bnbAddress = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';

    // USDT Address
    const usdtAddress ='0x55d398326f99059fF775485246999027B3197955';

    // Create a contract instance for the PancakeSwap Router
    const router = new ethers.Contract(routerAddress, pancakeSwapRouterABI, provider);

    // Call the getAmountsOut function to get the price of your token in BNB
    router.getAmountsOut(ethers.utils.parseUnits('1', 18), [tokenContract, bnbAddress])
      .then((result) => {
        const tokenPriceInBNB = ethers.utils.formatUnits(result[1], 18);
        setTokenPriceInBNB(tokenPriceInBNB);
      })
      .catch((error) => {
        console.error('Error fetching token price:', error);
      });

      // Call the getAmountsOut function to get the price of your token in BNB
    router.getAmountsOut(ethers.utils.parseUnits('1', 18), [bnbAddress, usdtAddress])
    .then((result) => {
      const tokenPriceInUSDT = ethers.utils.formatUnits(result[1], 18);
      const finalPrice = tokenPriceInBNB * tokenPriceInUSDT;
      setTokenPrice(finalPrice.toFixed(10));
    })
    .catch((error) => {
      console.error('Error fetching token price:', error);
    });

  }, [tokenPriceInBNB, tokenContract]);


  return (
    <div className="div-self">
      <div className="card-s">
        <div className="card-header-s">
          <div className="flex-logo">
            <div className="logo-div">
              <img
                src="./logo.png"
                className="company-logo"
                alt="Company Logo"
              />
            </div>
          </div>
          <div className="d-flex-auto">
            <span className="header-text-s">{title}</span>
          </div>
        </div>

        <div className="mt-15" align="center">
          <h3 style={{ color: 'yellow', textAlign:'center' }}>Scan this QR Code and send BNB direct to this address, you will receive the tokens immediatly (Only BNB Accepted).</h3>
          <QRCode
            title="Payment QR"
            value={contractAddress}
          />
          <h6 className="claim-h">Price: ${tokenPrice}</h6>
        </div>

        <div>
          <div className="text-center">
            <p className="para-s">
              {description}
            </p>
          </div>
          <div className="contract-div">
            <p>
              Easy Buy Contract :{" "}
              <span className="contract-font">
                {contractAddress}
              </span>
            </p>
          </div>
          <br/>
          <div className="text-center"> 
            <a href={'https://bscscan.com/token/' + tokenContract} target='_blank' rel="noreferrer"><img src='/bsc.png' alt='' width={48} height={48}/></a>
            <a href={TwitterText} target='_blank' rel="noreferrer"><img src='/twitter.svg' alt='' width={48} height={48}/></a>
            <a href={TelegramText} target='_blank' rel="noreferrer"><img src='/telegram.png' alt='' width={48} height={48}/></a>
            <a href={FacebookText} target='_blank' rel="noreferrer"><img src='/facebook.png' alt='' width={48} height={48}/></a>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default App;
